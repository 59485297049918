import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`Wow, it’s June already – and between attending graduation events and making summer plans there remains only very little time to think about investing.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b62fe47aa36da302b993f53d35915580/21b4d/sp500.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTElEQVQoz42SDUoEMQyF9/43VNAbiEynbf77JKnjrqJg4KPZtH15m86NeeL15QmvL8/o7R3hAjf6YoUAoUXmj3tJFIyzd/Q+cDMVnO0dc7R9GQYsvVNCd6p2nYFVjYUxiSDCuJkZrlgLUFs7/4UMD0BsgTUq7+Q4hsM8QERbMCLqyloLb4fg6IpY3+UiFgYZJntxDEUbBhKHqsHdMMbYgmtFdReLOpAXzHeDLZzOo8jmao4+tdZwr1rVVe8O036bVk4yUnQ7yu4pILjOZpM0kfn6bJhrOXQT8JxoZ76abnF3ECuOLiAxvB1cDXLIy+0TxTLGUq41lEBzboeTGK0NKBNCZh2Cbc42ClyXUygJL1eXwzTCzLiNyfXX/oqc63+jXnmyop0DTIQ5ZxV/kvWcz7X/uCbprPe+BevbcquB/4WIFNfvfM3HPGee+xkfd8Rgysf/QgwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sp500",
            "title": "sp500",
            "src": "/static/b62fe47aa36da302b993f53d35915580/c1b63/sp500.png",
            "srcSet": ["/static/b62fe47aa36da302b993f53d35915580/5a46d/sp500.png 300w", "/static/b62fe47aa36da302b993f53d35915580/0a47e/sp500.png 600w", "/static/b62fe47aa36da302b993f53d35915580/c1b63/sp500.png 1200w", "/static/b62fe47aa36da302b993f53d35915580/21b4d/sp500.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`But regardless of what’s keeping you busy right now; it is worth looking at the S&P 500. The market passed a bullish golden cross on January 27, but we were still in a bear market. As the market continues to melt up, the index just crossed over the 10% off-peak line, signifying the beginning of a new bull market. Let’s hope this holds, but given the latest inflation figures, there might be good reasons to being mildly optimistic.`}</p>
    <div style={{
      "margin": "auto",
      "width": "300px"
    }}>
      <p><a parentName="p" {...{
          "href": "https://www.invest-like-a-pro.com/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
        }}><span parentName="a" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "129.33333333333331%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAC4jAAAuIwF4pT92AAAETklEQVRIx+2Pa2yTVRiAT7eWbTCi8RKc4i2SKBpwCky6EKMxQaOOse/r6AbbFBAG0nYta+kuXcdlwDZhDOwusLmR4RisINuAgJJopqBOE4OYaFQS5AcZzDATA+WyfucxX1sUdH+M/PQkT97znjfvc95XfDvNQOeURPqmjuGTVCPHnjbS+VQiR6aa6J0yhoFUA8MzBBdmCIamC4amjc6F6VHEhaJpnHam8YsnjfNlaZz3p/Gzz8xZ/0xOr0lncEM6V98xE9piJtRsJtRpJdRdSGifh1BwCaHgUkIdWYTeTSNUPRNBsxX2WOFILnyaByfy4Ms8+DofThbADwvhzFIYWgR0MdrRCBO+VMW17+chZG0WWoeCdkBFHragHctG67eifZ6D9kUOciAX+U0u8uxbSHkRKUGGY4yAFtaQ10c4PtDHvFovQm5WkJ0qsteC3G9BBi3ITgvyPQtaQEWrV5EBBRnMQf64CTncQfjaEcLaScLyt8h8pwYHSan7CFGxD3F9nYLcpiB3qshdKrJLRe61IPfE6M5G9mQj+3ORvw8gR4ZAngV+BS5z6tw5prT0I6r6SN50FBEqUQj7s9CqFbStCnK7imxXo1Pr4h4L8kMr2nEFbTAA2iWCJ75jSeN+Cro+JqW6j7Fre3m45jApGw8iLpcqjFSqsF6BmixkvQJNCrRHp9V6shk5aoX+HMKfvcyGvnqErxex6n1ESRfC20WcHkv3RPMrnkyuel/ljCeT4QqVcNVcrtQoXNuiMtKkQpsCHRlc3T0Xf+s6xLqDGNb2YKoMYly9j7jK/Rh0yrowLG9C7LUVsMhdzgRXE6n2Wr5yLuCiK5Nh9xyGPK/T78xmY3EhL5bVE+/rIcnbiamoFUNhQ0RgcLZhcLVjWNaIYfFWRELVAUTVIUSVvkYnrznX85Mjgw5bLi84q0iyBRCOnQh7O3G27YgVUQy2HRhWNGMoDETRhcsaEMnVh0muOUTchj5M/iDjvS1M8u/A6NuL8PciVn9AvD8YQej4uhHl3YiS3Qh3RxTPrmh0tiFMb28jwdFIgi3AGHsD49zNCHcbJncrY90tJHpaSXC3YlrZgtG1HaMzhqMJo70Bo70Roy0Wl9YhErNcxGc4iM+wRzDOKcKYYcc0x0FCpjNWc5CU5SJZLb4VS4ybcvHo4jXM8taTvmoLs1bVM921iVcqm3iueDNPLF/PS+UBni/ZSkpBBWMtbsbP85CcHWXcKIgHF/oxu+uYXdEQaXymqJbZ/saI/NmiWlIdNZg9dUx8049prosktZhEZWVEPppU3Gn1cu+CMu7LL4+Qku/jnvml0XuBjwl55ZFc//ihhZU8sng1k5as5a7ckpjUzTjLX4g7rF4eeKMi0qBHHX0avVmPuvR+XZxfHpHruV67e37pn1PejNCt+hp/J1EpHvX9Ru3mlW8RGienY3zy32G6cZ9s/gfCkPIYtxMRN/Fxbif/C/+78A/cf0l3w15GZwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "book cover",
              "title": "book cover",
              "src": "/static/589ef713ff51ae424f66ea58fc2c4710/c1b63/book-cover.png",
              "srcSet": ["/static/589ef713ff51ae424f66ea58fc2c4710/5a46d/book-cover.png 300w", "/static/589ef713ff51ae424f66ea58fc2c4710/0a47e/book-cover.png 600w", "/static/589ef713ff51ae424f66ea58fc2c4710/c1b63/book-cover.png 1200w", "/static/589ef713ff51ae424f66ea58fc2c4710/d61c2/book-cover.png 1800w", "/static/589ef713ff51ae424f66ea58fc2c4710/97a96/book-cover.png 2400w", "/static/589ef713ff51ae424f66ea58fc2c4710/742d3/book-cover.png 2550w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></a></p>
    </div>
    <p>{`In the past years, I had many conversations with investors of various knowledge levels. And while most investors have some understanding of returns and volatility, I picked up one common theme: The difficulty of picking the right portfolio to achieve individual financial goals. On `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader.com`}</a>{`, we tried to address this issue with `}<a parentName="p" {...{
        "href": "/articles/portfolio-wizard/"
      }}>{`our portfolio wizard`}</a>{`. However, it seems to me that more education is needed.`}</p>
    <p>{`I thought a lot about how to possibly present this knowledge on TuringTrader.com, but ultimately chose to take a different route: I am writing a book. But because I am inherently impatient, I decided not to first write the book in its entirety and publish it afterwards. Instead, I opted for a free online-first publication, released one chapter at a time.`}</p>
    <p>{`Today, I am for the first time sharing this effort with a broader audience; you can find the project on `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Invest-Like-a-Pro.com`}</a>{`. And yes, this is just the beginning, with the first section, `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/basics/financial-planning/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`The Basics`}</a>{`, completed but still in need of some editing.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACc0lEQVQoz22Ty08TcRDHfwg+DgocvHnAKzcv/gOc9c5J5NXKw4uJUfEgXhAJNUETMZGYkOiBGIMHL0o0iFiethDaLbRby1MLUkuh2310dz9mdxEJcZJv5puZ+X0z88uMyOTypDe3yGazqKqKZVmYpunB4ZZN0fRg2zZ/bStnEFpWGA5lqX2eoiawxG6+gCgWixw155kj9L/EcDhH7cAKVR2LnGxfQDSGEHUhzt6IsJNXEYZheLW2jWV7nXBI62Msz9PRDNeH1rjYvYhoDCPq5hBN8whfhBPXJESjxPlbcbYyOU/QmcQV2jfdNAmv73FlMIVo+IaoDyOuhhH185T5JY77Y5T5YpT6JEr9EqIhStXtOOlfWUfw38hZ1eDF1CYXumKIpjm3kxJflFJ/lGM+yeWiOeKhyeFRSpqjiPoI524uklN0hGkaLG8bdLz9QfW9JURDjLJGmcrWJJXtMuVtccpbZSraEi7KW5KcbklQ0R7nTGvcjZ3yJ6i+K5NTNMTSZo7+kEz3aIrA2CpPpmUezUZ5EJQIBGV6v6TomYjRPR6nayxBYCbC46kk3Z9W6Asu0zue5GEwTv/XDQqqgRiJZhlZW+ON/J1BaYnhn/N0Tk9zf3aSnrlZOqdmeJWIUfNylIYPYzyT5nm3KuN7P0FfOMKloXHuTAa5/PozqfRvhKZ7f2iZR/bGiWGRMRSXr+zm2TRyB7ntgo5mwMK6RnpPZ2Bmg8X1NELXNQqq6i614xWHaw4vuDFd08greQxdpaB5OUUtoGs6mqah6yqKrpDdUdF0A2FZtnsVzoIX9y/ksC+aRTRNx9mGgws6XFP0vOWMaNv8Ab+A315PGNaDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "commitment equity",
            "title": "commitment equity",
            "src": "/static/07b0f77bb4030ded9843c32e35fa2d1a/c1b63/commitment-equity.png",
            "srcSet": ["/static/07b0f77bb4030ded9843c32e35fa2d1a/5a46d/commitment-equity.png 300w", "/static/07b0f77bb4030ded9843c32e35fa2d1a/0a47e/commitment-equity.png 600w", "/static/07b0f77bb4030ded9843c32e35fa2d1a/c1b63/commitment-equity.png 1200w", "/static/07b0f77bb4030ded9843c32e35fa2d1a/21b4d/commitment-equity.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`The book will also contain two strategies, designed specifically with the investment scenarios outlined in the book in mind. The first strategy is named `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{` and is a tactical take on `}<a parentName="p" {...{
        "href": "/portfolios/robbins-all-seasons/"
      }}>{`Tony Robbins’ All-Seasons portfolio`}</a>{`. If you feel that you have heard that before, you are right: it has a lot of things in common with our trusted `}<a parentName="p" {...{
        "href": "/portfolios/tt-rain-or-shine/"
      }}>{`Rain or Shine`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+UlEQVQoz42SO4sUQRSF+1f4FxYEIwWzTQRTQdDIzNBwTQTxEShqoLKCgeBGJoqIoq6CmmgurLqvmV0dcB/jznb39HR3vas+qRoWRkXxwuGeurfqUJx7s7xq2Oz/oKmGDOoK6xwEn7C+o9kZWWL4EAghJG6sw0a4cQ7e46xFKUVmzPhBqwPvv5V8rUas7ohUu/F6l1tv8sQZa1EKw+8hvUVbz7AsyfCO50s5px+ucunlJmeedDh6d5leITh2r8OpB52xkJLMd/tMzy7y6GPO44VdhLVsjATn5td5tVxi6pJMGcOR2Q5Tlxc5cX+NqfNd9l/scPjmZw5c+8T0nS/c/vCdK+96HJ9bYd/MCgevL3FyrsvZZz1mnvY4dHWNCy82sKoh08YwFJ6icfRHmrL1Cbu1o2g8ee3YLh39yjJoDLWAvDV0ixHLgxELWw2VCLxdzdkuGzJrLf8Kg8YRfXPJSI9DIqlDg0WjEOmsI3QaiiEOL05xErEmnaF1GuMdW7pgYCpK26C8HQ8pgAuBxilqJymH5cQPw5+/a51K4uN++OVO2FujiVpRFNFDjcEivab1ChMsMhhqL1P+34jibduSDUVNISpaKWhkm3jeDhNXUiGlRAiRclzciEm+h3gn2pdFZe88zjl8gic4n7J1NtW11kRrIv8bYj9q/QQeJzwtTfphQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "commitment rolling",
            "title": "commitment rolling",
            "src": "/static/73bb7f2ffc813c5b1ce0f3f75b95411b/c1b63/commitment-rolling.png",
            "srcSet": ["/static/73bb7f2ffc813c5b1ce0f3f75b95411b/5a46d/commitment-rolling.png 300w", "/static/73bb7f2ffc813c5b1ce0f3f75b95411b/0a47e/commitment-rolling.png 600w", "/static/73bb7f2ffc813c5b1ce0f3f75b95411b/c1b63/commitment-rolling.png 1200w", "/static/73bb7f2ffc813c5b1ce0f3f75b95411b/21b4d/commitment-rolling.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`However, there are a few important differences. For once, `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{` does not use leverage. This is a good thing, as it avoids the costly leveraged ETFs, and the volatility drag associated with them. Nonetheless, the tracking chart shows how `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{` has no trouble keeping up with `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-rain-or-shine/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Rain or Shine`}</a>{`. This is a tremendous success, given that `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{` has 25% less exposure.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3klEQVQoz5WSTWsTcRDGx4voxU8geO1NxK/gyVsPbSAo+EKUWj0VA/VSIYgHDyp6KYIXUVKlVj0oaA8BA6KQprtpbBMiMWmTNiSbzctuNtm3nyQRDVax/cPvMjP855lnRm6+KnPwisLU0wKxjRbFWhfbcdn/8+h0OsjMQgkJqMgFFbmkcHT2KydvZzl1P0fwcYGr0S2uvywTebvDnQ8VHsaqPIrXePK5zkJCZzGp81ppEMu2qWl1JPyihJxJcWhqHTmfRoIpJKAgEwoymUQmV4YEVpBgEjm7ipxTkIsKElI5cDmFhBSO3UhTrmjITLSETKgcnk4zNpfl9IM809FNIu+2mY9XiSY03qg6y5kmH7+1+JRvkdhss1oyWNs2WK+Y5KoWxXqXRrOJ3Htf4Xgkx3y8wo7ZAeyBH//HHcHD9x16vR4SXixw5FqGsbkNvusWLi6GbdNx+jj/xnWwRnFsTNNEpp7lkXGVE7cyeL4/XJi/j+WO1Oq6jswuFZHxJOGlrUHQcX18f2/82bnRaCB3l8tIKEm6bA2Crrd3ef2JTKs38M627aHCtWKT519qfW1Y3WFyFMuyMAxjcLS7ct0ulZpGXdNot9s4joP86ub9fax+Ud/s/se7895vK3/6/wNHIAz76H5OvwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "commitment monte carlo",
            "title": "commitment monte carlo",
            "src": "/static/000f58d280e94fd490945425a7617552/c1b63/commitment-monte-carlo.png",
            "srcSet": ["/static/000f58d280e94fd490945425a7617552/5a46d/commitment-monte-carlo.png 300w", "/static/000f58d280e94fd490945425a7617552/0a47e/commitment-monte-carlo.png 600w", "/static/000f58d280e94fd490945425a7617552/c1b63/commitment-monte-carlo.png 1200w", "/static/000f58d280e94fd490945425a7617552/21b4d/commitment-monte-carlo.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`And as this strategy is designed to reach critical financial goals, it is optimized to provide the highest dependable return. The Monte Carlo simulation shows that `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{` beats `}<a parentName="p" {...{
        "href": "https://www.turingtrader.com/portfolios/tt-rain-or-shine/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Rain or Shine`}</a>{` by roughly %1 at the 5th percentile and over 25 years – while offering a very similar risk profile. If you anticipte a typical withdrawal rate during retirement around 4%, that lead is meaningful.`}</p>
    <p>{`But while the metrics might not be enough to justify a new strategy, something else is: `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/strategies/commitment/?utm_source=tt-com&utm_medium=email&utm_campaign=newsletter-june-2023"
      }}>{`Commitment`}</a>{`'s internal mechanisms for trend-following and money-management are much advanced. This makes me very confident that the strategy will perform as designed for years to come.`}</p>
    <p>{`OK, that’s it for today. I hope you enjoy your summer and find some well-deserved time to decompress and unwind. And maybe even read a (my) book. And if you have any corrections, edits, or comments: I’d love to hear them.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      